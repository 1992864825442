import axios from 'axios'
import {Langage} from "../language";

const base_url = 'https://api.iux1.com/'
let myApp = 'newpharma';
const myLang = 'french'
const getText = (key) => {
  return Langage[key][myLang]
}

export const uploadPicture = async(image) => {
    const formData = new FormData();
    formData.append('image', image, image.name || 'captured.png');
    const result = await axios({
        method: 'post',
        url: `${base_url}api-v2/picture/upload`,
        data: formData
      });
    
    return result.data
}

export const processAnalysis = async(id) => {

  try{
    const result = await axios({
      method: 'put',
      url: `${base_url}api-v2/picture/${id}/analysis`,
      data: {  
        "traitement":{  
             "traitementRides": true,
             "traitementRegulation": true,
             "traitementSensibilite": true,
             "traitementEclat": true,
             "traitementHydratation": true,
             "traitementTaches": true,
             "traitementImperfections": true,
             "traitementPores": true
        },
        "age": 52 // todo
     }
    });
    return result.data
  }catch(error) {
    console.error(error)
  }
}

export const submit = async (form) => {

  let final = []
  let tmp = {}
  form.forEach((item, index) => {
    if(item.type === 'bot')
      { 
        if(Object.keys(tmp).length > 0){
          final.push(tmp)
          tmp = {}
        }
        tmp.question = item.message
        tmp.response = ''
        tmp.brief = null
        tmp.uid = item.id
      }
    else {
      tmp.response = tmp.response.concat("", item.message)
    }
        
  })

   try {
    const request = {
      session: final,
      version: 2
    }
    const response = await axios.post(`https://cactus.gydtech.io/api/forms/skin`, request);
    if(response.data.id){
      // todo stocker le id questoinner 
    }
    
  } catch (error) {
    console.error(error);
  } 
};

export const submitBesoins = async (states) => {
  const form = states.messages;
    let final = [];
    if(myApp !== 'chatbot-hair') {
      final = [
        {
          "question": getText('age_q'),
          "response": states.age,
          "uid": -3,
          isSec: true
        },
        {
          "question": getText('gender_q'),
          "response": states.genre,
          "uid": -2,
          isSec: true
        },
        {
          "question": getText('today_type'),
          "response": states.skinType,
          "uid": -1,
          isSec: true
        }
      ]
    }
    let tmp = {}
    form.forEach((item, index) => {
      if(item.type === 'bot')
        { 
          if(Object.keys(tmp).length > 0){
            final.push(tmp)
            tmp = {}
          }
          tmp.question = item.message
          tmp.response = ''
          tmp.brief = item.payload.brief || null
          tmp.besoin = item.payload.besoin || '_BEFORE'
          tmp.uid = item.payload.id || item.payload.uid
          tmp.isSec = item.payload.isSec
        }
      else {
        tmp.response = tmp.response.concat("", item.message)
      }
          
    })
    final = final.sort((a,b) => a.uid - b.uid);
    final = final.filter(f => f.isSec);
    final = final.map(f => {
      delete f.isSec;
      if(f.brief === null) {
        delete f.brief;
      }
      if(myApp === 'chatbot-hair') {
        delete f.besoin;
      }
      return f;
    });
    // console.log(final)

      let token = {token: ''};
      const mail = (final.find(r => r.response && r.response.includes('@')) || {response: ''}).response;
      if(mail) {
        let customer_id
        const data = {
          age: states.age,
          "app":"abbi",
          email: mail,
          firstname : states.fullName.split(' ')[0] || null,
          lastname: states.fullName.split(' ')[1] || states.fullName.split(' ')[0] ,
          group_id: 1,
          pwd: "effimetrix"
        }

        let res = await findProfileByEmail(mail)
        if(res.items.length){
          customer_id = res.items[0].id
        } else{
          const tmp = await registerProfile(data)
          customer_id = tmp.fk_customer_id
        }

        token = await getToken(customer_id)
      }
  
     try {
      const request = {
        session: final.filter(f => f.uid !== 16),
        version: 2
      }
      // console.log(final)
       let endpoint = 'besoins';
       if(myApp === 'chatbot-hair') {
         endpoint = 'hair';
       }
      const response = await axios.post(`https://cactus.gydtech.io/api/forms/${endpoint}`, request);
      if(response.data.id){
        window.location.href = `https://democactus.gydtech.io?idQuestionner=${response.data.id}&token=${token.token}&pharmaId=1886715`;
      }
      
    } catch (error) {
      console.error(error);
    } 
  };

export const getQuestions = async(type = '') => {

  try{
    return await axios({
      method: 'get',
      url: `https://cactus.gydtech.io/api/questions${type}`
    })
  } catch (error){
    console.error(error)
  }

}

export const skinType = async(scores, age) => {

  try{
    const result = await axios({
      method: 'post',
      url: `https://cactus.gydtech.io/api/skintype`,
      data: {scores, age}
    });

    return result.data
  } catch (error){
    console.error(error)
  }
  
}

export const registerProfile = async(data) => {

  try{
    const result = await axios({
      method: 'post',
      url: `https://api.effimetrix.care/profiles`,
      data: data
    });

    return result.data
  } catch (error){
    console.error(error)
  }
  
}

export const findProfileByEmail = async(email) => {

  try{
    const result = await axios({
      method: 'get',
      url: `https://api.effimetrix.care/profiles/find/${email}`
    });

    return result.data
  } catch (error){
    console.error(error)
  }
  
}
// retoit le token d un profile en lui enviyant son id
export const getToken = async(id) => {

  try{
    const result = await axios({
      method: 'post',
      url: `https://api.effimetrix.care/customers/token/${id}`
    });

    return result.data
  } catch (error){
    console.error(error)
  }
  
}


/////////////////////////
// AI D-ID FACE & TALK //
/////////////////////////

const baseUrlDid = 'https://api.d-id.com';
const D_ID_API_TOKEN = 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ik53ek53TmV1R3ptcFZTQjNVZ0J4ZyJ9.eyJodHRwczovL2QtaWQuY29tL2ZlYXR1cmVzIjoidGFsa3MiLCJodHRwczovL2QtaWQuY29tL2N4X2xvZ2ljX2lkIjoiIiwiaXNzIjoiaHR0cHM6Ly9hdXRoLmQtaWQuY29tLyIsInN1YiI6Imdvb2dsZS1vYXV0aDJ8MTA1ODQ3NzUwODQyOTg2ODE0OTUyIiwiYXVkIjpbImh0dHBzOi8vZC1pZC51cy5hdXRoMC5jb20vYXBpL3YyLyIsImh0dHBzOi8vZC1pZC51cy5hdXRoMC5jb20vdXNlcmluZm8iXSwiaWF0IjoxNjg3NzE0MDU4LCJleHAiOjE2ODc4MDA0NTgsImF6cCI6Ikd6ck5JMU9yZTlGTTNFZURSZjNtM3ozVFN3MEpsUllxIiwic2NvcGUiOiJvcGVuaWQgcHJvZmlsZSBlbWFpbCByZWFkOmN1cnJlbnRfdXNlciB1cGRhdGU6Y3VycmVudF91c2VyX21ldGFkYXRhIG9mZmxpbmVfYWNjZXNzIn0.AVE6GK_VYHxh7M_r3cRpECy9aj9N_TsVMuWO82OsfPK1K41Nj6MXQp0859h6UUFGiOewKcm9UjN0Tv4kws4s-khxVTwlReb0BPEdKAXS1OBAGZ-VMm8wMLQPp83DaWlFRMpevhnySI_jsZkTmzOZEaLH3hBrQ1IrTrZvrkYHbEAng5EDDJv9rayHfi9O-RssoBjIBDbRLzGMz0hqzOWYnXfT4uTsbTSsDE5vHstkmmsZkvaKO6Y4ZWfgoAx2RIfuF1VxGMmhdt-reWcvEh-dFzpYdPRn5o1hg-1Y6zOMadcuD4rrQD0Dt-xTqh5MoSBnayJoW1_HHyAGabLfeCe7gA';

export const uploadPictureD_id = async(image) => {
  const formData = new FormData();
  formData.append('image', image, image.name || 'captured.png');
  const result = await axios({
    method: 'POST',
    url: `${baseUrlDid}/images`,
    headers: {
      accept: 'application/json',
      'content-type': 'multipart/form-data',
      authorization: D_ID_API_TOKEN
    },
    data: formData
  });

  if(result.data && result.data.url) {
    localStorage.setItem('MY_AI_PIC', result.data.url);
  }

  return create_a_talk();
}

export const create_a_talk = async() => {
  const result = await axios({
    method: 'POST',
    url: `${baseUrlDid}/talks`,
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      authorization: D_ID_API_TOKEN
    },
    data: {
      script: {
        type: 'text',
        subtitles: 'false',
        provider: {type: 'microsoft', voice_id: 'fr-FR-DeniseNeural'},
        ssml: 'false',
        input: 'Enchanté, Quel âge avez-vous ?'
      },
      config: {fluent: 'false', pad_audio: '0.0'},
      source_url: localStorage.getItem('MY_AI_PIC')
    }
  });

  if(result.data && result.data.id) {
    localStorage.setItem('MY_AI_ID', result.data.id);
  }

  return get_a_talk();
}

export const get_a_talk = async() => {
  const result = await axios({
    method: 'GET',
    url: `${baseUrlDid}/talks/${localStorage.getItem('MY_AI_ID')}`,
    headers: {
      accept: 'application/json',
      authorization: D_ID_API_TOKEN
    }
  });

  if(result.data && result.data.result_url) {
    localStorage.setItem('MY_AI_TALKING_FACE', result.data.result_url);
    document.querySelector('#ai-video').src = localStorage.getItem('MY_AI_TALKING_FACE');
    return result.data;
  } else {
    setTimeout(() => {
      return get_a_talk();
    }, 3000)
  }
}
